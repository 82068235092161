export default class AsyncLock {
    
    public disable: () => void
    public promise: Promise<void>

    constructor () {
      this.disable = () => {}
      this.promise = Promise.resolve()
    }
  
    enable () {
      this.promise = new Promise(resolve => this.disable = resolve)
    }

  }